<script>
import AdditionalField from "./additional-field.vue";
import AdditionalVersion from "./additional-version.vue";
import {mapGetters} from "vuex";
import draggable from 'vuedraggable'
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: 'additional-versionable-field',

    components: {TransitionToggle, AdditionalVersion, AdditionalField, draggable},

    props: {
        modelValue: {},
        state: {
            type: Boolean,
        },
        field: {
            type: Object,
        },
        error: {
            type: String
        },
        multiline: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    computed: {
        ...mapGetters({
            fieldVersions: 'fieldVersion/all'
        }),

        model: {
            get: function () {
                return JSON.parse(JSON.stringify(this.modelValue || null));
            },

            set: function (value) {
                // console.log(value)
                this.$emit('update:modelValue', value)
            },
        }
    },

    data: function () {
        return {
            version: {
                name: null,
                translation_key: null,
                date: null
            },
            add: '',
            show: false
        }
    },

    methods: {
        updateItem: function (index, value) {
            this.model[index].item.value = value
            this.updateModel()
        },

        updateVersion: function (value, index) {
            if (index >= 0) {
                this.model[index].version = Object.assign({}, this.model[index].version, value)
                this.model.map((item, iIndex) => {
                    if (iIndex !== index && item.version.name === value.name) {
                        item.version.date = value.date
                    }
                    return item;
                });
            } else if (!index) {
                this.model.map((item) => {
                    if (item.version.name === value.name) {
                        item.version.date = value.date
                    }
                    return item;
                });
            }
            if (this.version.name === value.name) {
                this.version.date = value.date;
            }
            this.updateModel();
        },

        updateAdd: function (item) {
            this.add = item || {}
        },

        addItem: function () {
            this.updateVersionDate()

            this.model.unshift({
                version: this.version,
                item: {
                    value: this.add
                }
            });
            this.version = {
                name: null,
                translation_key: null,
                date: this.setDefaultVersionDate()
            };
            this.add = '';
            this.updateModel()
            this.show = true
        },

        removeItem: function (index) {
            this.model.splice(index, 1)
            this.updateModel()
        },

        updateVersionDate: function () {
            if (! this.model || ! this.model.length) {
                return
            }

            const prev = this.model.find(item => item.version.name === this.version.name);

            if (prev) {
                this.version.date = prev.version.date;
            }
        },

        updateModel: function () {
            this.$emit('update:modelValue', this.model.map((item, index) => {
                item.item.sort = index + 1
                return item;
            }))
        },

        setDefaultVersionDate: function () {
            const date = new Date()

            this.version.date = date.formattedDate()
        },
    },

    mounted() {
        this.setDefaultVersionDate()
    }
}
</script>

<template>
    <fieldset class="form-fieldset rounded pt-4 p-3 mb-4">
        <legend>{{ (field.translation_key ? $t(field.translation_key).ucFirst() : field.name) }}</legend>
        <div class="row">
            <additional-field
                class="col-12 mb-2"
                :class="{'col-md-6 mb-sm-0': !multiline}"
                :model-value="add"
                :field="field"
                @update:modelValue="newValue => updateAdd(newValue)"
            ></additional-field>
            <div class="col-12 mb-2" :class="{'col-sm-6 col-md-3 col-lg-2 mb-sm-0': !multiline}">
                <label
                    :for="field.transformed_name + '_version'"
                    class="form-label"
                >{{ $t('dynamic_fields::field_versions.columns.name').ucFirst() }}</label>
                <input
                    :id="field.transformed_name + '_version'"
                    class="form-control"
                    list="versions"
                    :placeholder="$t('dynamic_fields::field_versions.placeholders.name').ucFirst()"
                    v-model="version.name"
                    @change="updateVersionDate"
                />
                <datalist id="versions">
                    <option v-for="(fieldVersion, index) in fieldVersions" :key="index" :value="fieldVersion.translation_key ? $t(fieldVersion.translation_key).ucFirst() : (fieldVersion.name || fieldVersion.date)">{{ fieldVersion.translation_key ? $t(fieldVersion.translation_key).ucFirst() : (fieldVersion.name || fieldVersion.date) }}</option>
                </datalist>
            </div>
            <div class="col-12 mb-2" :class="{'col-sm-6 col-md-3 col-lg-2 mb-sm-0': !multiline}">
                <label
                    :for="field.transformed_name + '_date'"
                    class="form-label"
                >{{ $t('dynamic_fields::field_versions.columns.date').ucFirst() }}</label>
                <input
                    :id="field.transformed_name + '_date'"
                    type="datetime-local"
                    class="form-control"
                    v-model="version.date"
                    @change="updateVersion(version)"
                />
            </div>
            <div class="col-12 mt-4 mb-2" :class="{'col-lg-1 mb-sm-0 mt-sm-0': !multiline}">
                <div class="d-flex align-items-end justify-content-end w-100 h-100">
                    <b-button class="w-100" variant="primary" @click="addItem" :title="$t('base.add').ucFirst()">+</b-button>
                </div>
            </div>
            <div class="col-12" :class="{'col-md-1': !multiline, 'mb-2': multiline}">
                <label class="form-label d-none" :class="{'d-sm-block': !multiline}">{{ $tc('dynamic_fields::field_versions.field_version', 2).ucFirst() }}</label>
                <span class="form-control btn btn-light" @click="show = !show" :title="$t('base.show_item', {item: $tc('dynamic_fields::field_versions.field_version', 2)}).ucFirst()">
                    <i class="fas fa-angle-down" :class="{active: show}"></i>
                </span>
            </div>
            <transition-toggle class="mt-4">
                <div class="col-12" v-if="!show && model && model.length">
                    <div class="row pb-1 hover rounded">
                        <div class="col-md-6 col-lg-7">
                            <additional-field
                                class="flex-grow-1"
                                :model-value="model[0].value"
                                :field="field"
                                :state="state"
                                :error="error"
                                :disabled="true"
                            ></additional-field>
                        </div>
                        <additional-version
                            v-model="model[0].version"
                            :field="field"
                            :disabled="true"
                        ></additional-version>
                    </div>
                </div>
            </transition-toggle>
            <transition-toggle class="mt-4">
                <draggable
                    v-model="model"
                    item-key="item.value"
                    @change="updateModel"
                    v-if="show"
                >
                    <template #item="{element, index}">
                        <div class="col-12">
                            <div class="row pb-1 hover rounded">
                                <div class="col-md-6 col-lg-7">
                                    <div class="d-flex">
                                        <div class="d-flex align-items-end me-2">
                                    <span class="btn btn-white">
                                        <i class="fas fa-sort"></i>
                                    </span>
                                        </div>
                                        <additional-field
                                            class="flex-grow-1"
                                            :model-value="element.item.value"
                                            :field="field"
                                            :state="state"
                                            :error="error"
                                            @update:modelValue="newValue => updateItem(index, newValue)"
                                        ></additional-field>
                                    </div>
                                </div>
                                <additional-version
                                    v-model="element.version"
                                    :field="field"
                                    @update:modelValue="newValue => updateVersion(newValue, index)"
                                ></additional-version>
                                <div class="col-12 col-lg-1">
                                    <div class="d-flex w-100 h-100 align-items-end">
                                        <b-button variant="danger" @click="removeItem(index)" :title="$t('base.delete').ucFirst()">
                                            <i class="fas fa-trash"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </transition-toggle>
        </div>
    </fieldset>
</template>

<style scoped>
    .hover:hover {
        background-color: rgba(0,0,0, 0.02);
    }

    .custom-bg {
        overflow: hidden;
        clip-path: polygon(0 0, 100% 0%, 100% 50%, 50% 100%, 0 50%);
    }

    .btn:hover .fa-angle-down {
        transition: 0.2s;
        transform: translateY(20%);
    }

    .btn .fa-angle-down.active {
        transition: 0.2s;
        transform: rotateZ(180deg);
    }
</style>
