<script>
import {defineComponent} from 'vue'
import FileViewer from "./file-viewer.vue";

export default defineComponent({
    name: "file-input",

    components: {FileViewer},

    props: {
        modelValue: {},

        placeholder: {
            type: String
        },

        disabled: {
            type: Boolean,
            default: false
        },

        required: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        model: {
            get: function () {
                return this.modelValue instanceof File ? this.modelValue : null
            },

            set: function (value) {
                this.$emit('update:modelValue', value)
            },
        },

        name: function () {
            return this.modelValue && this.modelValue.name
        },
    },

    methods: {
        setFile: function (event) {
            this.$emit('update:modelValue', event.target.files)
        },

        icon: function () {
            if (!this.modelValue) {
                return 'fa-file'
            }

            switch (this.modelValue.extension) {
                case 'pdf':
                    return 'fa-file-pdf';
                case 'png':
                case 'jpeg':
                case 'jpg':
                    return 'fa-file-image';
                case 'docx':
                case 'doc':
                    return 'fa-file-word';
                case 'xls':
                case 'xlsx':
                    return 'fa-file-excel';
                default:
                    return 'fa-file';
            }
        },
    },

    data: function () {
        return {
            open: 1
        }
    },
})
</script>

<template>
    <div class="d-flex w-100">
        <div v-if="modelValue && modelValue.storage_path" class="d-flex align-items-center btn btn-outline-primary me-3" @click="open++">
            <span
                  :title="name"
                  class="rounded-circle me-1"
            >
                <i :class="icon()"
                   class="fas fa-file"
                ></i>
                <file-viewer :click-event="open" :path="modelValue.storage_path"></file-viewer>
            </span>
            <small>{{ name }}</small>
        </div>
        <input
            :disabled="disabled"
            :placeholder="placeholder"
            class="form-control flex-grow-1"
            type="file"
            @change="setFile($event)"
        />
    </div>
</template>

<style scoped>

</style>
