<script>
export default {
    name: 'transition-toggle',

    data: function () {
        return {
            active: false,
            height: '100%',
        }
    },

    methods: {
        setHeight: function () {
            if (this.height === '100%') {
                this.height = this.$refs.trans.clientHeight + 'px';
            }
        },
    }
}
</script>

<template>
    <div ref="trans">
        <transition type="animation" @enter="setHeight" @after-leave="height = '100%'">
            <slot></slot>
        </transition>
    </div>
</template>

<style scoped>
@keyframes slide-left {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: v-bind(height);
        opacity: 1;
    }
}

@keyframes slide-right {
    0% {
        height: v-bind(height);
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

/* we will explain what these classes do next! */
.v-enter-from {
    height: 0;
    overflow: hidden;
}

.v-enter-active {
    animation: slide-left 0.3s ease-in;
    overflow: hidden;
}

.v-leave-active {
    animation: slide-right 0.3s ease-in;
    overflow: hidden;
}
</style>
