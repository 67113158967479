<script>
import FileInput from "./file-input.vue";

export default {
    name: 'additional-field',
    components: {FileInput},

    props: {
        modelValue: {},
        state: {
            type: Boolean,
            default: null
        },
        field: {
            type: Object,
        },
        error: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    computed: {
        model: {
            get: function () {
                const defValue = this.field.field_type.options.type === 'datetime-local' && !this.modelValue ? new Date().formattedDate() : null

                return JSON.parse(JSON.stringify(this.modelValue || defValue));
            },

            set: function (value) {
                if (this.field.field_type.options.type !== 'file') {
                    this.$emit('update:modelValue', value);
                }
            },
        },

        options: function () {
            return (this.field && this.field.options) || {}
        },

        width: function () {
            return this.options && this.options.width_on_the_form
                ? 'col-' + this.options.width_on_the_form
                : ''
        },
    },

    methods: {
        setFile: function (event) {
            if (this.field.field_type.options.type === 'file') {
                this.$emit('upload', event.target.files)
            }
        },
    }
}
</script>

<template>
    <div :class="width">
        <label
            :class="{'text-danger': state === false}"
            :for="field.transformed_name"
            class="form-label"
        >{{ field.translation_key ? $t(field.translation_key).ucFirst() : field.name }}
            <span v-if="field.unit" class="ms-2">({{ field.unit }})</span>
        </label>
        <template v-if="field.field_type.options.type === 'select'">
            <select
                :id="field.transformed_name"
                v-model="model"
                :class="{'border-danger': state === false}"
                :name="field.transformed_name"
                :required="field && field.options && field.options.required"
                class="form-select"
                :disabled="disabled"
            >
                <option :value="model === undefined ? undefined : null">{{
                        field.placeholder_translation_key ? $t(field.placeholder_translation_key).ucFirst() : field.placeholder
                    }}
                </option>
                <template v-if="field.options.options && field.options.options.length">
                    <option v-for="(option, optionIndex) in field.options.options" :key="optionIndex"
                            :value="option.value || option.label">{{
                            option.translation_key ? $t(option.translation_key).ucFirst() : option.label
                        }}
                    </option>
                </template>
            </select>
        </template>
        <template v-else-if="field.field_type.options.type === 'radio'">
            <div v-for="(option, optionIndex) in field.options.options"
                 :key="optionIndex"
                 class="form-check"
            >
                <label :for="field.transformed_name + '_' + optionIndex" class="form-check-label">{{
                        option.translation_key ? $t(option.translation_key).ucFirst() : option.label
                    }}</label>
                <input
                    :id="field.transformed_name + '_' + optionIndex"
                    v-model="model"
                    :class="{'border-danger': state === false}"
                    :name="field.transformed_name"
                    :required="field && field.options && field.options.required"
                    :value="option.value || option.label"
                    class="form-check-input"
                    type="radio"
                    :disabled="disabled"
                />
            </div>
        </template>
        <template v-else>
            <div class="input-group">
                <template v-if="field.field_type.options.type === 'file'">
                    <file-input
                        :id="field.transformed_name"
                        :name="field.transformed_name"
                        :class="{'border-danger': state === false}"
                        v-model="model"
                        :placeholder="field.placeholder_translation_key ? $t(field.placeholder_translation_key).ucFirst() : field.placeholder"
                        :required="field && field.options && field.options.required"
                        :disabled="disabled"
                        @change="setFile($event)"
                    ></file-input>
                </template>
                <template v-else>
                    <input
                        :id="field.transformed_name"
                        v-model="model"
                        :class="{'border-danger': state === false}"
                        :name="field.transformed_name"
                        :placeholder="field.placeholder_translation_key ? $t(field.placeholder_translation_key).ucFirst() : field.placeholder"
                        :required="field && field.options && field.options.required"
                        :type="(field && field.field_type && field.field_type.options && field.field_type.options.type) || 'text'"
                        class="form-control"
                        :disabled="disabled"
                        @change="setFile($event)"
                    />
                    <div v-if="field.unit" class="input-group-text">{{ field.unit }}</div>
                </template>
            </div>
        </template>
        <div :class="{'d-block': state === false}" class="invalid-feedback">
            {{ error }}
        </div>
    </div>
</template>

<style scoped>

</style>
